.footerIMG {
  height: 5%;
  width: 10%;
  padding-right: 5px;
  padding-left: 5px;
}

.footerMail {
  height: 5%;
  width: 5%;
  padding-right: 10px;
  padding-left: 10px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="footerIMG"] {
    height: 20%;
    width: 25%;
  }
}

@media only screen and (max-width: 768px) {
  [class*="footerMail"] {
  	height: 20%;
    width: 15%;
  }
}

.iframeClean {
	filter: none;
}