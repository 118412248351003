body {
	background-color: #e8f5ff;
	color:#001c31;
}

body,h1,h2,h3,h4,h5,h6 {
	font-family: "Alegreya", sans-serif;
	letter-spacing: 1px;
}
.w3-bar,h1,button {font-family: "Arvo", sans-serif}
.fa-anchor,.fa-coffee {font-size:200px}