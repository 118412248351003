.logoNav {
  width: 4%;
}

.headerStick {
	background-color: #ceeaff;
	width: 100%;
	position: fixed;
}

.Home {
	padding-left:20px;
	padding-right:20px;
}

.homeColor {
  background-color: #9bd4ff;
}

.hoverable:hover{
	background-color:#f5fbff!important
}