.photo {
  width: 100%;
}

.card {
  background-color: #e8f5ff;
}

.book {
  height: 400px;
  width: 5%;
  border-bottom: 4px solid black;
  border-right: 2px solid black;
  border-top-left-radius: 50px 20px;
  border-top-right-radius: 50px 20px;
  float: left;
  text-align: center;
}

.shelf {
  height: 20px;
  width: 100%;
  background-color: #964B00;
  float: left;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="book"] {
    height: 400px;
    width: 10%;
    padding-left: 10px;
  }
}


span.bookTitle {
  writing-mode: vertical-rl;
  padding-top: 30px;
  text-align: center;
  font-weight: bold;
}
