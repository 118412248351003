.business {
  height: 30%;
  width: 30%;
}

.a {
  background-color: white; /* Green */
  border: 2px solid black;
  border-radius: 4px;
  color: black;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.a:hover {
  background-color: #DAAD86;
}

code {
  font-family: MyFancyCustomFont, monospace;
  font-size: inherit;
  color: green;
  background-color: #111740;
}

/* Code in text */
p > code,
li > code,
dd > code,
td > code {
  background: #ffeff0;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  border-radius: .2rem;
}